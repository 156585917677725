import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import zeus from "../src/assets/logo2.svg";
import zeustext from "../src/assets/FONTLOGO.svg";

const Contents = () => {
  const [copied, setCopied] = useState(false);
  const CA = "hGs6tpDEioXSgFHChLjK4i9CtsKVTpdtsNZDPmy1TZt";
  const shortCA = `${CA.slice(0, 10)}...${CA.slice(-10)}`; // Shorten the CA value

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <div>
      <div className="container mx-auto columns-1 md:columns-1 lg:columns-2 px-4 ">
        <img
          className="float-right transform transition-transform duration-300 ease-in-out hover:scale-150 lg:pr-0 pr-12 md:pr-14"
          src={zeus}
        />
        <div className="text-center items-center justify-center py-8 lg:hidden">
          <CopyToClipboard text={CA} onCopy={() => setCopied(true)}>
            <h1 className="text-base font-bold border-b border-custom-blue inline-block text-custom-blue cursor-pointer hover:text-text-hover relative">
              CA: <br /> {shortCA}
            </h1>
          </CopyToClipboard>
          {copied && <span className="text-custom-blue">Copied!</span>}
        </div>
        <div className="text-center lg:text-left mx-auto">
          <div className="items-center justify-center  md:py-2 sm:py-8">
            <img
              src={zeustext}
              className="h-14 md:h-auto mx-auto md:mx-auto lg:mx-0 max-w-sm shadow-none transition-shadow duration-300 ease-in-out md:pt-14"
              alt="zeusthecoin"
            />
          </div>

          <p className="text-xl sm:text-2xl md:text-2xl italic font-extrabold md:leading-relaxed leading-loose lg:text-left text-color py-6 lg:pt-10">
            A lil mystical god with lightning eyes.
            <br />
            A stormterror to jeeters.
            <br />
            Ruling over Olympus with a grin.
            <br />
            God of the degens.
            <br />
          </p>
          <div className="flex flex-wrap space-x-4 justify-center lg:justify-start">
            <a
              className="my-button mt-6"
              href={`https://dexscreener.com/solana/${CA}`}
              target="_blank"
            >
              Buy Now
            </a>
          </div>
        </div>
        <div className="items-center text-2xl md:text-xl justify-center text-center sm:mt-4 hidden lg:flex pt-8">
          <CopyToClipboard text={CA} onCopy={() => setCopied(true)}>
            <h1 className=" text-custom-blue font-bold border-b border-custom-blue inline-block cursor-pointer hover:text-text-hover relative">
              CA: {CA}
            </h1>
          </CopyToClipboard>
          {copied && <span className="text-custom-blue">Copied!</span>}
        </div>
      </div>

      <div className="overflow-hidden whitespace-nowrap mt-16 py-1 md:py-2 font-black text-xl md:text-4xl w-full border-solid border-4 border-r border-l border-custom-blue mb-12">
        <div className="flex animate-marquee text-custom-blue">
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
          <span className="px-6">$ZEUS</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
