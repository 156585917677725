import React from "react";
import Navbar from "./Navbar";
import Contents2 from "./Contents2";
import Contents1 from "./Contents1";
import Footer from "./Footer";
import "./App.css";

function App() {
  return (
    <>
      <div className="background sm-px-3">
        <Navbar />
        <Contents1 />
        <Contents2 />
        <Footer />
      </div>
    </>
  );
}

export default App;
