import React from "react";
import dexscreener from "./assets/dexscreen.svg";
import twitter from "./assets/twitter.svg";
import telegram from "./assets/telegram.svg";
import header from "./assets/navbarlogo.svg";
import youtube from "./assets/youtube.svg";
import headerMobile from "./assets/navbarmobile.svg";

const Navbar = () => {
  const handleReload = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const CA = "hGs6tpDEioXSgFHChLjK4i9CtsKVTpdtsNZDPmy1TZt";

  return (
    <header className="shadow ">
      <nav className="container mx-auto px-10 md:px-20 py-8">
        <div className="flex justify-between items-center ">
          <div className="text-4xl font-semibold hidden md:flex">
            <a href="#" onClick={handleReload}>
              <img src={header} className="w-48" />
            </a>
          </div>
          {/* mobile */}
          <div className="text-4xl font-semibold  md:hidden">
            <a href="#" onClick={handleReload}>
              <img src={headerMobile} className="w-12 lg:w-16" />
            </a>
          </div>

          <div className="flex items-center  space-x-2 lg:space-x-4">
            <a
              href={`https://dexscreener.com/solana/${CA}`}
              target="_blank"
              className="hover:bg-text-hover rounded-full"
            >
              <img src={dexscreener} alt="Icon 1" className="lg:w-12 w-10" />
            </a>
            <a
              href="https://x.com/zeusthecoin"
              target="_blank"
              className="hover:bg-text-hover rounded-full"
            >
              <img src={twitter} alt="Icon 3" className="lg:w-12 w-10 " />
            </a>
            <a
              href="https://t.me/zeusthecoin"
              target="_blank"
              className="hover:bg-text-hover rounded-full"
            >
              <img src={telegram} alt="Icon 3" className="lg:w-12 w-10" />
            </a>
            <a
              href="https://www.youtube.com/@zeusthecoin"
              target="_blank"
              className="hover:bg-text-hover rounded-full"
            >
              <img src={youtube} alt="Icon 3" className="lg:w-12 w-10" />
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
