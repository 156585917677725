import React from "react";
import {
  FaEnvelope,
  FaTelegramPlane,
  FaYoutube,
  FaMedium,
} from "react-icons/fa"; // Import the email, Telegram, and Twitter icons
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="w-full py-4 bottom-0 text-color">
      <div className="flex justify-center items-center py-4 space-x-4">
        <a href="mailto:zeusthecoin@gmail.com" target="_blank">
          <div className="rounded-full  p-2 border-2 border-custom-blue hover:bg-custom-text-color">
            <FaEnvelope className="text-custom-blue" />
          </div>
        </a>

        <a href="https://t.me/zeusthecoin" target="_blank">
          <div className="rounded-full  p-2 border-2 border-custom-blue hover:bg-custom-text-color">
            <FaTelegramPlane className="text-custom-blue" />
          </div>
        </a>

        <a href="https://www.x.com/zeusthecoin" target="_blank">
          <div className="rounded-full  p-2 border-2 border-custom-blue hover:bg-custom-text-color">
            <FaXTwitter className="text-custom-blue" />
          </div>
        </a>

        <a
          href="https://medium.com/@zeusthecoin/zeus-the-next-mili-memescoin-01be339ea96e"
          target="_blank"
        >
          <div className="rounded-full  p-2 border-2 border-custom-blue hover:bg-custom-text-color">
            <FaMedium className="text-custom-blue" />
          </div>
        </a>

        <a href="https://www.youtube.com/@zeusthecoin" target="_blank">
          <div className="rounded-full  p-2 border-2 border-custom-blue hover:bg-custom-text-color">
            <FaYoutube className="text-custom-blue" />
          </div>
        </a>
      </div>
      <div className="container mx-auto text-center">
        <p>© 2024 ZeusTheCoin</p>
      </div>
    </footer>
  );
};

export default Footer;
