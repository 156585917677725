import React from "react";
import back from "../src/assets/back2.svg";

const Contents = () => {
  return (
    <div className="container mx-auto pb-4 min-h-full">
      <img src={back} />
    </div>
  );
};

export default Contents;
